<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import customTable from "@/components/paginate-table.vue";
import mobileView from "../../../components/mobile-view.vue";
import { detectScreen } from "@/_helpers";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, customTable, mobileView },
  page: {
    title: "Data Ukuran",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "#/",
        },
        {
          text: "Produk",
          href: "#/Produk",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      screenWidth: detectScreen(),
      dataKey: "color",
      apiUrl: process.env.VUE_APP_API_BASE_URL + "sizes",
      columns: [
        {
          prop: "name",
          label: "Ukuran",
          width: "150",
          center: true,
        },
        {
          prop: "description",
          label: "Deskripsi",
        },
        {
          prop: "created_at",
          label: "Tgl di Buat",
          date: true,
          sortable: true,
        },
      ],
    };
  },
  methods: {
    onScreenResize() {
      this.screenWidth = detectScreen();
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout v-if="screenWidth >= 860">
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body>
        <custom-table
          ref="dataTable"
          :with-add="false"
          :with-form-add="false"
          :form-url="'/produk/ukuran/tambah'"
          :with-filter-name="true"
          :with-delete="false"
          :with-print="false"
          :with-edit="false"
          :with-show="false"
          :no-action="true"
          :data-url="apiUrl"
          :data-key="dataKey"
          :data-columns="columns"
          filter-with="name"
          title-filter="Filter Berdasarkan Ukuran Pakaian"
        ></custom-table>
      </b-card-body>
    </b-card>
  </Layout>
  <mobileView
    v-else
    ref="dataTable"
    :with-add-data="false"
    title-filter="Filter Berdasarkan Ukuran Pakaian"
    :with-filter-text="true"
    :card-default="false"
    :card-size="true"
    skeleton-height="100px"
    skeleton-radius="5px"
    :cardLoad="5"
    :form-url="'/produk/ukuran/tambah'"
    :data-url="apiUrl"
  ></mobileView>
</template>
